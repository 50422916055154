import React from "react";
import Card from "react-bootstrap/Card";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
            Hi Everyone, I am <span className="purple">Edoardo Bavaro </span>
            from <span className="purple"> Italy.</span>
            <br />23-year-old student, majoring in Computer Engineering. I learned back-end development languages ​​as a self-taught, notions enhanced later by the course of study.
            <br />
            Additionally, I am enthusiastic about cybersecurity.
            <br />
            I am a determined, proactive person with good time management, qualities that have allowed me to combine study, work and experiences abroad.
            <br />
            <br />
          </p>

        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
